import { HeadFC, PageProps } from 'gatsby'
import React, { BaseSyntheticEvent } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowUpRightFromSquare } from '@fortawesome/free-solid-svg-icons'

// Models
import { PublisherModel, ComicModel } from '../../../models'

// Icons
import NoCover from '../../../assets/icons/NoCover.png'
import { Email, Facebook, Instagram, Twitter } from '../../../assets/icons'

// Components
import PageTemplate from '../PageTemplate'
import ComicCard from '../../atoms/ComicCard'
import DefaultHelmet from '../../atoms/DefaultHelmet'

// graphQL
import { getGraphComics } from '../../../graphQL'

// Styles
import {
  publisherTop,
  publisherLink,
  publisherInfo,
  publisherTech,
  publisherPage,
  publisherTechTitle,
  publisherCover,
  publisherTitle,
  publisherComics,
  publisherDetail,
  publisherCoverWrapper,
} from './style.module.scss'

const PublisherTemplate: React.FC<PageProps<any, PublisherModel>> = ({
  pageContext: {
    name,
    page,
    image,
    email,
    comics,
    twitter,
    facebook,
    instagram,
    description,
  },
}) => {
  const allComics = getGraphComics()
  return (
    <PageTemplate>
      <h1 className={publisherTitle}>{name}</h1>
      <div className={publisherTop}>
        <div className={publisherCoverWrapper}>
          <img
            className={publisherCover}
            src={`https://comiccolombiano.com/.img/publishers/${image}`}
            onError={(e: BaseSyntheticEvent) => {
              e.target.src = NoCover
            }}
            alt={`Foto de ${name}`}
          />
        </div>
        <div className={publisherInfo}>
          {description && (
            <div className={publisherDetail}>
              {description || 'Descripción pendiente'}
            </div>
          )}
          {page && (
            <>
              <h3 className={[publisherTitle, publisherPage].join(' ')}>
                Página web
                <a
                  className={publisherLink}
                  href={page}
                  target="_blank"
                  rel="noreferrer"
                >
                  <FontAwesomeIcon icon={faArrowUpRightFromSquare} />
                </a>
              </h3>
            </>
          )}

          {(facebook || email || instagram || twitter) && (
            <div className={publisherTech}>
              <h3 className={[publisherTitle, publisherTechTitle].join(' ')}>
                Información de contacto
              </h3>
              {instagram && (
                <a
                  href={`https://www.instagram.com/${instagram}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  <div className={publisherLink}>
                    <Instagram />
                    <div>{instagram}</div>
                  </div>
                </a>
              )}
              {facebook && (
                <a
                  href={`https://www.facebook.com/${facebook}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  <div className={publisherLink}>
                    <Facebook />
                    <div>{facebook}</div>
                  </div>
                </a>
              )}
              {twitter && (
                <a
                  href={`https://www.twitter.com/${twitter}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  <div className={publisherLink}>
                    <Twitter />
                    <div>{twitter}</div>
                  </div>
                </a>
              )}
              {email && (
                <div className={publisherLink}>
                  <Email />
                  <div>{email}</div>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
      <h2 className={publisherTitle}>Cómics</h2>
      <div className={publisherComics}>
        {comics &&
          comics.length > 0 &&
          comics?.map((comic) => (
            <div key={`publisher_comic_${comic.id}`}>
              <ComicCard
                key={`publisher_comic_${comic.id}`}
                comic={
                  allComics.find((c) => c.id === String(comic.id)) as ComicModel
                }
              />
            </div>
          ))}
      </div>
    </PageTemplate>
  )
}

export const Head: HeadFC<any, PublisherModel> = ({
  pageContext: { name, description },
}) => (
  <>
    <title>{name} - Comic Colombiano</title>
    <meta
      name="description"
      content={
        description ??
        '¡Novelas gráficas, revistillas, webcómics y más! Conoce aquí el catálogo más grande de cómic colombiano y sus autores. ¡La inscripción es gratuita!'
      }
    />
    <meta
      name="keywords"
      content={[
        name?.split(' ').join(','),
        'colombiano,autor,base,de,datos,ilustrador,escritor,diseñador,novela,grafica,arte,revistillas,catalogo',
      ].join(',')}
    />
    <DefaultHelmet />
  </>
)

export default PublisherTemplate
